<template>
    <div>
        <!-- CAROUSEL-->
    <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide                
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>

        <br>
        <!-- <h1 class="titles">
          
          <span style="color: #d82125">G</span>
          <span style="color: #d02175">o</span>
          <span style="color: #f6c921">b</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">e</span>
          <span style="color: #07aaba">r</span>
          <span style="color: #d82125">n</span>
          <span style="color: #d02175">o</span>
          
        </h1> -->

    <b-row align-h="center" align="center" class="mt-3 mb-3">
      <b-col cols="10" md="4" >
        <b-img  fluid src="https://teotihuacan.gob.mx/files/imagenes/diflogo.jpg"></b-img>
      </b-col>
    </b-row>

    <h3 class="titles mt-5 " >
        <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Información Financiera LGCG</span>
        
    </h3>
    <b-row class="mt-5" align-h="center" >
        <b-col cols="11" md="10" lg="8" class="mb-5">
            <div class="accordion" role="tablist">

                

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-1-24 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2024</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-1-24"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Primer%20Trimestre%202024/Titulo%20IV" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Primer%20Trimestre%202024/Titulo%20V" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-2-24 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2024</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-2-24"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Segundo%20Trimestre%202024/Titulo%20IV" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Segundo%20Trimestre%202024/Titulo%20V" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-3-24 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Tercer Trimestre 2024</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-3-24"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Tercer%20Trimestre%202024/Titulo%20IV" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Tercer%20Trimestre%202024/Titulo%20V" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-4-24 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Cuarto Trimestre 2024</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-4-24"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Cuarto%20Trimestre%202024/Titulo%20IV" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Cuarto%20Trimestre%202024/Titulo%20V" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-1-23 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-1-23"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Primer%20Trimestre%202023/Titulo%20IV" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Primer%20Trimestre%202023/Titulo%20V" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-2-23 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-2-23"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Segundo%20Trimestre%202023/Titulo%20IV" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Segundo%20Trimestre%202023/Titulo%20V" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-3-23 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Tercer Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-3-23"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Tercer%20Trimestre%202023/Titulo%20IV/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Tercer%20Trimestre%202023/Titulo%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-4-23 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Cuarto Trimestre 2023</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-4-23"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Cuarto%20Trimestre%202023/Titulo%20IV/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Cuarto%20Trimestre%202023/Titulo%20V/" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-0 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Primer Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-0"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://drive.google.com/drive/folders/1Flnbys4YKDijFX78Az-PXcRsP14wF_YZ?usp=sharing" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://drive.google.com/drive/folders/1ouDYrX1lJJOvwlhsdzE4izmwwU_RfrwV?usp=sharing" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                          
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-02 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Segundo Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-02"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://drive.google.com/drive/folders/1nryz8VWLtHnX2eXUNySRjGDGswLAmTEb?usp=sharing" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-03 style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Tercer Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-03"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://drive.google.com/drive/folders/1Gk4lpv5e6xbZKbwJ-K1zaEl77DclQyjL?usp=share_link" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título IV</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-04t style="width:100%;   text-align:left; "><b-icon icon="files"/> Información Financiera Cuarto Trimestre 2022</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-04t"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/difconta/2022/CUARTO%20TRIMESTRE/TITULO%20V" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Título V</a>
                          </b-card-text>
                      </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-CP style="width:100%;   text-align:left; "><b-icon icon="files"/> Cuenta Pública</b-button>
                  </b-card-header>                
                  <b-collapse id="accordion-CP"  accordion="my-accordion" role="tabpanel">
                      <b-card-body style="text-align: left">
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/dif/contabilidad/Cuenta%20Publica/Cuenta%20Publica%202023" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2023</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://teotihuacan.gob.mx/files/difconta/2022/OTRA%20INFORMACION/CTA%20PUB%202022.pdf" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2022</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://drive.google.com/file/d/1kjxzVYsuxomQdKduSExwuHhqwOhh7MZ2/view?usp=share_link" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2021</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://drive.google.com/file/d/1Ek0Vgrxg9ay4zyMExz-9VleKtYyLqOUn/view?usp=share_link" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2020</a>
                          </b-card-text>
                          <b-card-text >
                            <a href="https://drive.google.com/file/d/1dhglq-0pAZwIu6eRHhjVGVYxbn1l1T66/view?usp=share_link" target="_blank"><b-icon icon="file-earmark-text-fill"></b-icon> Cuenta Pública 2019</a>
                          </b-card-text>
                         
                      </b-card-body>
                  </b-collapse>
                </b-card>

            </div>
          </b-col>
        </b-row>


        
    
    <b-row align-h="center">
    
    <!--   <b-col cols="10" sm="8" md="6" lg="4" class="mt-5 mb-5" >
        <b-container>
          <b-img :src="require('../assets/MARIO OFICIAL.png')" fluid rounded class="c-shadow-sm" ></b-img>
        </b-container>
        <br>
        <b-div class="mt-5">
          <h4><span><b>C.P Mario Paredes de la Torre</b></span></h4>
          <h5><span>Presidente Municipal</span></h5>
        </b-div>
      </b-col>
     
          -->  
        
    </b-row>

    <b-row  v-for="empleado in resources.directorio_dif" :key="empleado.num" align-h="center" class="mt-5">
      <b-col cols="10" sm="6" md="4" lg="4" >
          <div :id="empleado.num"></div>
          <b-container class="mb-3">
            <b-img :src="'/files/directorio/dif/'+empleado.num+'.jpg'" fluid rounded class="c-shadow-sm" ></b-img>
          </b-container>
          <h4 ><b><b-icon icon="person-fill"/> {{empleado.nombre}}</b></h4>
          <h6>{{empleado.cargo}}</h6>
          <br>          
        </b-col>
    </b-row>

    </div>
</template>
<script>
  import axios from "axios";
  export default {
    components: {
    },
    mounted: function(){
      var host = location.protocol + '//' + location.host;
      axios({
          method: 'get',
          url: host+'/files/gobierno.json',
      })
      .then(res => {
          this.resources = res.data;
      }).catch(err => {
        alert(err);  
      });
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
      }
    }
    
  }
</script>